<template>
  <div class="archive-news-and-announcements page">
    <SectionPageHeader/>
    <ArchiveNewsAndAnnouncements />
    <Footer />
  </div>
</template>

<script>
import SectionPageHeader from "@/components/archive-news-and-announcements/SectionPageHeader.vue";
import ArchiveNewsAndAnnouncements from "@/components/archive-news-and-announcements/ArchiveNewsAndAnnouncements.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "archive-news-and-announcements",
  head: {
    title: { inner: 'Archive News And Announcements' }
  },
  components: {
    SectionPageHeader,
    ArchiveNewsAndAnnouncements,
    Footer
  }
}
</script>

<style lang="scss">
</style>

