<template>
    <section class='row archive'>
        <div class="offset-lg-1 col-12 col-lg-10">

            <pre>{{ categories }}</pre>

            <!-- Tabs -->
            <!-- <div class="overflow-nav-archive">
                <ul class="nav nav-archive">
                    <li class="nav-item">
                        <a class="nav-link active" href="#">{{ $t('archiveNews.navAll') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">{{ $t('archiveNews.navNews') }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">{{ $t('archiveNews.navAnnouncements') }}</a>
                    </li>
                </ul>
            </div> -->
            

            <!-- Archive of Posts -->
            <div class="row archive-of-posts">
                <h2 class="no-news">No news and announcements available.</h2>
                <PostPreviewNewsAndAnnouncements v-for="(news) in newsAndAnnouncements" :key="news.id" :id="news.id" :categories="categories" :category="news.category" :image="`bg-post-1.png`" :title="news.enTitle" :description="news.enContent" :postDate="news.postDate" :author="news.author"/>
            </div>

            <!-- Pagination -->
            <!-- <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                    <li class="page-item disabled"><a class="page-link" href="#">{{ $t('archiveNews.navPrev') }}</a></li>
                    <li class="page-item active" aria-current="page"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">{{ $t('archiveNews.navNext') }}</a></li>
                </ul>
            </nav> -->

        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import PostPreviewNewsAndAnnouncements from '@/components/partials/PostPreviewNewsAndAnnouncements.vue';
import axios from "axios";

export default {
    name: "ArchiveNewsAndAnnouncements",
    components: {
        PostPreviewNewsAndAnnouncements,
    },
    data: function() {
        return {
            newsAndAnnouncements: null,
            categories: null,
        };
    },
    created: function() {
        //this.getCategories();
        //this.getNewsAndAnnouncements();
    },
    methods: {
        getCategories() {
            axios.get("http://testdeployd.aezypay.com/category").then(res => (this.categories = res.data));
        },
        getCategoryById(categoryId) {
            this.categories.forEach(element => {
                if (element.id == categoryId) {
                    return element.enName;
                }
            });
        },
        getNewsAndAnnouncements() {
            axios.get("http://testdeployd.aezypay.com/announcements").then(res => (this.newsAndAnnouncements = res.data));
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/scss/archive-news-and-announcements/archive-news-and-announcements.scss';
</style>