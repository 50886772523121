<template>
    <section class='row section-page-header'>
        <div class="overlay-bg-archive-news-and-announcements"></div>

        <!-- Navbar: Not the right way to do it, will require refactoring -->
        <MainNav />

        <!-- Content -->
        <div class="offset-md-1 col-12 col-md-10 col-lg-5 col-xl-3 content">
          <h2 class="title">{{ $t('newsPageHeader.headerTitle1') }}<br />{{ $t('newsPageHeader.headerTitle2') }}</h2>
          <p class="paragraph">{{ $t('newsPageHeader.paragraph') }}</p>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import MainNav from '@/components/MainNav.vue';

export default {
  name: "SectionPageHeader",
  components: {
    MainNav,
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/scss/archive-news-and-announcements/section-page-header.scss';
</style>

